import { ICustomizations } from "./providers/types";

interface ITargeting {
  mobile: boolean
  size: [number, number]
}

interface IBid<P = unknown> {
  bidder: string
  params: P
}

function getBidderName (bidder: string) {
  switch (bidder) {
    case 'equativ': return 'smartadserver'
    case 'across33': return '33across' // swapped 33 for js variable compatibility
    case 'improveDigital': return 'improvedigital'
    default: return bidder
  }
}

function makeBid(bidder: string, params: unknown): IBid {
  const bidderName = getBidderName(bidder)
  return { bidder: bidderName, params };
}

type IBidderName = 
| 'ix'
| 'openx'
| 'medianet'
| 'across33'
| 'pubmatic'
| 'yahoossp'
| 'amx'
| 'appnexus'
| 'mediafuse'
| 'rubicon'
| 'sovrn'
| 'improveDigital'
| 'unruly'
| 'equativ'
| 'sharethrough'
| 'sonobi'
| 'onetag'
| 'nobid'
| 'cpmstar'

type IBidder = (params: any, bids: IBid[], targeting: ITargeting) => void

const bidders: {[bidder in IBidderName]: IBidder} = {
  ix: makeResponsiveBid('ix', {
    m300x250: params => ({ siteId: params.siteIdM }),
    m320x50: params => ({ siteId: params.siteIdMB }),
    d300x250: params => ({ siteId: params.siteIdD }),
    d728x90: params => ({ siteId: params.siteIdDB }),
    m1x1: params => ({ siteId: params.siteIdM }),
    d1x1: params => ({ siteId: params.siteIdD }),
  }),
  openx: makeResponsiveBid('openx', {
    m300x250: params => ({delDomain: params.delDomain, unit: params.unitM}),
    m320x50: params => ({delDomain: params.delDomain, unit: params.unitMB}),
    d300x250: params => ({delDomain: params.delDomain, unit: params.unitD}),
    d728x90: params => ({delDomain: params.delDomain, unit: params.unitDB}),
    m1x1: params => ({delDomain: params.delDomain, unit: params.unitM}),
    d1x1: params => ({delDomain: params.delDomain, unit: params.unitD}),
  }),
  medianet: makeResponsiveBid('medianet', {
    m300x250: params => ({cid: params.cid, crid: params.cridM}),
    m320x50: params => ({cid: params.cid, crid: params.cridMB}),
    d300x250: params => ({cid: params.cid, crid: params.cridD}),
    d728x90: params => ({cid: params.cid, crid: params.cridDB}),
    m1x1: params => ({cid: params.cid, crid: params.cridM}),
    d1x1: params => ({cid: params.cid, crid: params.cridD}),
  }),
  across33: makeResponsiveBid('across33', {
    m300x250: params => ({siteId: params.siteIdM, productId: params.productIdM }),
    m320x50: params => ({siteId: params.siteIdMB, productId: params.productIdMB }),
    d300x250: params => ({siteId: params.siteIdD, productId: params.productIdD }),
    d728x90: params => ({siteId: params.siteIdDB, productId: params.productIdDB }),
    m1x1: params => ({siteId: params.siteIdM, productId: params.productIdM }),
    d1x1: params => ({siteId: params.siteIdD, productId: params.productIdD }),
  }),
  pubmatic: makeResponsiveBid('pubmatic', {
    m300x250: params => ({ publisherId: params.pubIdM300x250 || params.pubIdM, adSlot: params.adSlotM300x250 || params.adSlotM }),
    m320x50: params => ({ publisherId: params.pubIdM320x50 || params.pubIdM, adSlot: params.adSlotM320x50 || params.adSlotMB }),
    d300x250: params => ({ publisherId: params.pubIdD300x250 || params.pubIdD, adSlot: params.adSlotD300x250 || params.adSlotD }),
    d320x50: params => ({ publisherId: params.pubIdD320x50 || params.pubIdD, adSlot: params.adSlotD320x50 || params.adSlotD }),
    d728x90: params => ({ publisherId: params.pubIdD728x90 || params.pubIdD, adSlot: params.adSlotD728x90 || params.adSlotB }),

    m1x1: params => ({ publisherId: params.pubIdM, adSlot: params.adSlotM }),
    d1x1: params => ({ publisherId: params.pubIdD, adSlot: params.adSlotD }),
  }),
  yahoossp: (params: ICustomizations['yahoossp'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('yahoossp', targeting.mobile ? {
      dcn: params.dcnM,
      pos: params.posM
    } : targeting.size[0] === 728 ? {
      dcn: params.dcnB,
      pos: params.posB
    } : {
      dcn: params.dcnD,
      pos: params.posD
    }))
  },
  amx: makeResponsiveBid('amx', {
    m300x250: params => ({ tagId: params.tagIdM300x250 || params.tagIdM, adUnitId: params.adUnitIdM300x250 || params.adUnitIdM }),
    m320x50: params => ({ tagId: params.tagIdM320x50 || params.tagIdM, adUnitId: params.adUnitIdM320x50 || params.adUnitIdMB }),
    d300x250: params => ({ tagId: params.tagIdD300x250 || params.tagIdD, adUnitId: params.adUnitIdD300x250 || params.adUnitIdD }),
    d320x50: params => ({ tagId: params.tagIdD320x50 || params.tagIdD, adUnitId: params.adUnitIdD320x50 || params.adUnitIdD }),
    d728x90: params => ({ tagId: params.tagIdD728x90 || params.tagIdD, adUnitId: params.adUnitIdD728x90 || params.adUnitIdB }),
    m1x1: params => ({ tagId: params.tagIdM, adUnitId: params.adUnitIdM }),
    d1x1: params => ({ tagId: params.tagIdD, adUnitId: params.adUnitIdD }),
  }),
  appnexus: makeResponsiveBid('appnexus', {
    m300x250: params => ({ placementId: params.placementIdM300x250 || params.placementIdM }),
    m320x50: params => ({ placementId: params.placementIdM320x50 || params.placementIdM }),
    d300x250: params => ({ placementId: params.placementIdD300x250 || params.placementIdD }),
    d320x50: params => ({ placementId: params.placementIdD320x50 || params.placementIdD }),
    d728x90: params => ({ placementId: params.placementIdD728x90 || params.placementIdD }),
    m1x1: params => ({ placementId: params.placementIdM }),
    d1x1: params => ({ placementId: params.placementIdD }),
  }),
  mediafuse: (params: ICustomizations['mediafuse'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('mediafuse', targeting.mobile ? { placementId: params.placementIdM } : { placementId: params.placementIdD }))
  },
  rubicon: makeResponsiveBid('rubicon', {
    m300x250: params => ({ accountId: params.accountId, siteId: params.siteIdM300x250 || params.siteIdM, zoneId: params.zoneIdM300x250 || params.zoneIdM }),
    m320x50: params => ({ accountId: params.accountId, siteId: params.siteIdM320x50 || params.siteIdM, zoneId: params.zoneIdM320x50 || params.zoneIdM }),
    d300x250: params => ({ accountId: params.accountId, siteId: params.siteIdD300x250 || params.siteIdD, zoneId: params.zoneIdD300x250 || params.zoneIdD }),
    d320x50: params => ({ accountId: params.accountId, siteId: params.siteIdD320x50 || params.siteIdD, zoneId: params.zoneIdD320x50 || params.zoneIdD }),
    d728x90: params => ({ accountId: params.accountId, siteId: params.siteIdD728x90 || params.siteIdD, zoneId: params.zoneIdD728x90 || params.zoneIdD }),
    m1x1: params => ({ accountId: params.accountId, siteId: params.siteIdM, zoneId: params.zoneIdM }),
    d1x1: params => ({ accountId: params.accountId, siteId: params.siteIdD, zoneId: params.zoneIdD }),
  }),
  sovrn: makeResponsiveBid('sovrn', {
    m320x50: (params) => ({ tagid: params.tagidM320x50 || params.tagidMB }),
    m300x250: (params) => ({ tagid: params.tagidM300x250 || params.tagidM }),
    d300x250: (params) => ({ tagid: params.tagidD300x250 || params.tagidD }),
    d320x50: (params) => ({ tagid: params.tagidD320x50 || params.tagidD }),
    d728x90: (params) => ({ tagid: params.tagidD728x90 || params.tagidB }),
    m1x1: params => ({ tagid: params.tagidM }),
    d1x1: params => ({ tagid: params.tagidD }),
  }),
  improveDigital: makeResponsiveBid('improveDigital', {
    m300x250: params => ({ placementId: params.placementIdM300x250 || params.placementIdM }),
    m320x50: params => ({ placementId: params.placementIdM320x50 || params.placementIdMB }),
    d300x250: params => ({ placementId: params.placementIdD300x250 || params.placementIdD }),
    d320x50: params => ({ placementId: params.placementIdD320x50 || params.placementIdD }),
    d728x90: params => ({ placementId: params.placementIdD728x90 || params.placementIdDB }),
    m1x1: params => ({ placementId: params.placementIdM }),
    d1x1: params => ({ placementId: params.placementIdD }),
  }),
  unruly: (params: ICustomizations['unruly'], bids: IBid[], _targeting: ITargeting) => {
    if (params && params.siteId) {
      return bids.push(makeBid('unruly', params))
    }
  },
  sonobi: makeResponsiveBid('sonobi', {
    m320x50: (params) => ({ placement_id: params.placement_id_m320x50 || params.placement_id_m, ad_unit: params.ad_unit_m }),
    m300x250: (params) => ({ placement_id: params.placement_id_m300x250 || params.placement_id_m, ad_unit: params.ad_unit_mb }),
    d300x250: (params) => ({ placement_id: params.placement_id_d300x250 || params.placement_id_d, ad_unit: params.ad_unit_d }),
    d320x50: (params) => ({ placement_id: params.placement_id_d320x50 || params.placement_id_d, ad_unit: params.ad_unit_d }),
    d728x90: (params) => ({ placement_id: params.placement_id_d728x90 || params.placement_id_d, ad_unit: params.ad_unit_b }),
    m1x1: params => ({ placement_id: params.placement_id_m, ad_unit: params.ad_unit_m }),
    d1x1: params => ({ placement_id: params.placement_id_d, ad_unit: params.ad_unit_d }),
  }),
  equativ: makeResponsiveBid('equativ', {
    m300x250: (params) => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdM, formatId: params.formatIdM300x250 }),
    m320x50: (params) => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdM, formatId: params.formatIdM320x50 }),
    d300x250: (params) => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdD, formatId: params.formatIdD300x250 }),
    d320x50: (params) => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdD, formatId: params.formatIdD320x50 }),
    d728x90: (params) => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdD, formatId: params.formatIdD728x90 }),
    m1x1: params => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdM, formatId: params.formatIdM }),
    d1x1: params => ({ networkId: params.networkId, siteId: params.siteId, pageId: params.pageIdD, formatId: params.formatIdD }),
  }),
  sharethrough: makeResponsiveBid('sharethrough', {
    m300x250: (params) => ({ pkey: params.pkeyM300x250 }),
    m320x50: (params) => ({ pkey: params.pkeyM320x50 }),
    d300x250: (params) => ({ pkey: params.pkeyD300x250 }),
    d320x50: (params) => ({ pkey: params.pkeyD320x50 }),
    d728x90: (params) => ({ pkey: params.pkeyD728x90 }),
    m1x1: params => ({ pkey: params.pkeyM }),
    d1x1: params => ({ pkey: params.pkeyD }),
  }),
  onetag: makeResponsiveBid('onetag', {
    m320x50: (params) => ({ pubId: params.pubId, ext: { placement_name: params.nameM320x50 || params.nameMB } }),
    m300x250: (params) => ({ pubId: params.pubId, ext: { placement_name: params.nameM300x250 || params.nameM } }),
    d300x250: (params) => ({ pubId: params.pubId, ext: { placement_name: params.nameD300x250 || params.nameD } }),
    d728x90: (params) => ({ pubId: params.pubId, ext: { placement_name: params.nameD728x90 || params.nameB } }),
    m1x1: params => ({ pubId: params.pubId, ext: { placement_name: params.nameM } }),
    d1x1: params => ({ pubId: params.pubId, ext: { placement_name: params.nameD } }),
  }),
  nobid: makeResponsiveBid('nobid', {
    m300x250: (params) => ({ siteId: params.siteId, placementId: params.placementIdM300x250 || params.placementIdM }),
    m320x50: (params) => ({ siteId: params.siteId, placementId: params.placementIdM320x50 || params.placementIdM }),
    d300x250: (params) => ({ siteId: params.siteId, placementId: params.placementIdD300x250 || params.placementIdD }),
    d320x50: (params) => ({ siteId: params.siteId, placementId: params.placementIdD320x50 || params.placementIdD }),
    d728x90: (params) => ({ siteId: params.siteId, placementId: params.placementIdD728x90 || params.placementIdD }),
    m1x1: params => ({ siteId: params.siteId, placementId: params.placementIdM }),
    d1x1: params => ({ siteId: params.siteId, placementId: params.placementIdD }),
  }),
  cpmstar: makeResponsiveBid('cpmstar', {
    m300x250: (params) => ({ placementId: params.placementIdM300x250 || params.placementIdM }),
    m320x50: (params) => ({ placementId: params.placementIdM320x50 || params.placementIdM }),
    d300x250: (params) => ({ placementId: params.placementIdD300x250 || params.placementIdD }),
    d320x50: (params) => ({ placementId: params.placementIdD320x50 || params.placementIdD }),
    d728x90: (params) => ({ placementId: params.placementIdD728x90 || params.placementIdD }),
    m1x1: params => ({ placementId: params.placementIdM }),
    d1x1: params => ({ placementId: params.placementIdD }),
  }),
}

export function makeBidFactory(theme: ICustomizations) {
  return (targeting: ITargeting): IBid[] => {
    const bids: IBid[] = []

    for (const bidder of Object.keys(bidders) as IBidderName[]) {
      const params = theme[bidder]
      if (params) {
        bidders[bidder](params, bids, targeting)
      }
    }

    return bids
  }
}

// type MakeBidCb<N extends IBidderName> = 

type BidTarget = string //`${'d' | 'm'}${number}x${number}`

function makeResponsiveBid<N extends IBidderName>(bidderName: N, bidTargets: { 
  [key in BidTarget]: (params: ICustomizations[N]) => IBid['params']
}) {
  return (params: ICustomizations[N], bids: IBid[], targeting: ITargeting) => {
    const target: BidTarget = `${targeting.mobile ? 'm' : 'd'}${targeting.size[0]}x${targeting.size[1]}`
    const makeBidTarget = bidTargets[target]

    if (makeBidTarget) {
      const bidParams = makeBidTarget(params) as Record<string, string | number>
      if (bidParams) {
        let missing = false
        for (const key of Object.keys(bidParams)) {
          if (!bidParams[key]) {
            // console.warn(`Bidder ${bidderName} has missing param ${key} for target ${target}`)
            missing = true
          }
        }

        if (!missing) {
          const bid = makeBid(bidderName, bidParams)
          bids.push(bid)
        }
      }
    }
  }
}
