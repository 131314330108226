type AdUnit = { code: string; bids: { bidder: string }[] };
type Auction = {
  auctionId: string;
  adUnits: AdUnit[];
  noBids: { bidder: string }[];
  bidsReceived: { bidder: string }[];
};

export class BidderMonitor {
  bidderStrikes: Record<string, number> = {};

  constructor(public maxNoBids = 1) {}

  checkBidders(auction: Auction) {
    const { noBids, bidsReceived } = auction;

    const biddersWithResponse = new Set(bidsReceived.map((bid) => bid.bidder));
    const biddersWithNoBids = new Set(noBids.map((bid) => bid.bidder));

    Array.from(biddersWithNoBids)
      // ONLY INCREMENT IF THE BIDDER DID NOT RESPOND
      .filter((bidder) => !biddersWithResponse.has(bidder))
      .forEach((bidder) => {
        this.bidderStrikes[bidder] = (this.bidderStrikes[bidder] || 0) + 1;

        if (this.bidderStrikes[bidder] >= this.maxNoBids) {
          this.removeBidderFromAdUnits(bidder);
        }
      });

    bidsReceived.forEach((bid) => {
      this.bidderStrikes[bid.bidder] = 0;
    });
  }

  removeBidderFromAdUnits(bidder: string) {
    try {
      const swpbjs = window.swpbjs;
      const adUnits = swpbjs.adUnits as AdUnit[];
      const codes = adUnits.map((unit) => unit.code);
      const updatedAdUnits = adUnits.map((unit) => ({
        ...unit,
        bids: unit.bids.filter((bid) => bid.bidder !== bidder),
      }));

      // REMOVE THE BIDDER FROM THE S2S CONFIG
      const config = swpbjs.getConfig()
      if (config && Array.isArray(config.s2sConfig)) {
        config.s2sConfig.forEach((conf: { bidders: string[] }) => {
          if (conf && Array.isArray(conf.bidders)) {
            conf.bidders = conf.bidders.filter((b: string) => b !== bidder);
          }
        })
        swpbjs.setConfig({
          s2sConfig: config.s2sConfig
        });
      }

      swpbjs.removeAdUnit(codes);
      swpbjs.addAdUnits(updatedAdUnits);
    } catch (e) {
      console.log(e);
    }
  }
}
