import { generateAds } from "./AdStack";
import { ICustomizations } from "./providers/types";

const widget = (c: string) => div('class="sellwild-widget"', c);
const widgetMobile = (c: string) => div('class="sellwild-widget sellwild-widget-mobile"', c);
const topbar = (c: string) => div('class="sellwild-topbar"', c);
const bottombar = (c: string) => div('class="sellwild-bottombar"', c);
const grid = (c: string) => div('class="sellwild-widget-grid"', c);
const grid2 = (c: string) => div('class="sellwild-widget-grid" style="--repeat: 2;"', c);
const grid4 = (c: string) => div('class="sellwild-widget-grid" style="--repeat: 4;"', c);
const banner = (c: string) => div('class="sellwild-widget-banner"', c);
const display = (c: string) => div(`class="sellwild-widget-display"`, c);
const displayShort = (c: string) => div(`class="sellwild-widget-display short"`, c);

interface GenerateOptions {
    ads: ReturnType<typeof generateAds>
    theme: ICustomizations
}

export function generateTriColumn({ ads, theme }: GenerateOptions) {
  const middleAd = theme.col3 === 'LDL'
  return widget(`
    ${poweredByTopbar(theme)}
    ${grid(`
    ${listing({ height: 176 })}
    ${listing({ height: 176 })}
    ${listing({ height: 176 })}
    `)}
    ${!theme.hideBannerTop ? banner(ads.useGoogleDisplay([728,90]) || ads.useTopBanner()) : ''}
    ${grid(`
    ${listing({ height: 136 })}
    ${listing({ height: 136 })}
    ${listing({ height: 136 })}
    `)}
    ${grid(middleAd ? `
    ${listing({ height: 140 })}
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    ${listing({ height: 140 })}
    ` : `
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    ${listing({ height: 140 })}
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    `)}
    ${poweredByBottomBar(theme)}
    ${makeStyles(theme)}
    `);
}

export function generateQuadColumn({ ads, theme }: GenerateOptions) {
  return widget(`
    ${poweredByTopbar(theme)}
    ${grid4(`
    ${listing({ height: 176 })}
    ${listing({ height: 176 })}
    ${listing({ height: 176 })}
    ${listing({ height: 176 })}
    `)}
    ${!theme.hideBannerTop ? banner(ads.useGoogleDisplay([728,90]) || ads.useTopBanner()) : ''}
    ${grid4(`
    ${listing({ height: 136 })}
    ${listing({ height: 136 })}
    ${listing({ height: 136 })}
    ${listing({ height: 136 })}
    `)}
    ${grid4(`
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    ${listing({ height: 162 })}
    ${listing({ height: 162 })}
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    `)}
    ${poweredByBottomBar(theme)}
    ${makeStyles(theme)}
    `);
}

export function generateDualColumn({ ads, theme }: GenerateOptions) {
  return widget(`
    ${poweredByTopbar(theme)}
    ${grid2(`
    ${listing({ height: 176 })}
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    `)}
    ${grid2(`
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    ${listing({ height: 176 })}
    `)}
    ${grid2(`
    ${listing({ height: 176 })}
    ${display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())}
    `)}
    ${poweredByBottomBar(theme)}
    ${makeStyles(theme, 630)}
  `);
}

export function generateSingleColumn({ ads, theme }: GenerateOptions) {
  const items = theme.col1
  ? theme.col1.split('').map(type => renderItem(ads, type)).join('\n')
  : [
    listing({ height: 136 }),
    display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay()),
    listing({ height: 136 }),
    display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay()),
    listing({ height: 136 })
  ].join('\n')
  return widgetMobile(`
    ${poweredByTopbar(theme)}
    ${items}
    ${poweredByBottomBar(theme)}
    ${makeStyles(theme, 630)}
  `);
}

function poweredByTopbar(theme: ICustomizations) {
  return topbar(`
    <div class="sellwild-topbar-titles">
    <h4>${theme.title ? decodeURIComponent(theme.title) :  'Top brands on Sellwild'}</h4>
    ${theme.title1 ? `<h6>${decodeURIComponent(theme.title1)}</h6>` :  ''}
    </div>
    ${theme.linkText && decodeURIComponent(theme.linkText)[0] !== '<' ? `<a href="https://sellwild.com/sell?p=${theme.partnerCode}">${decodeURIComponent(theme.linkText)}</a>` : theme.linkText ? decodeURIComponent(theme.linkText) : poweredBy(theme)}`);
}

function poweredBy (theme: ICustomizations) {
    return `<p>Powered by</p>
    <a target="_blank" href="https://sellwild.com?p=${theme.partnerCode}" rel="nofollow sponsored noopener" class="sellwild-logo">
    <img width="58" height="14" class="img-dark" src="https://widget.sellwild.com/assets/logo_white.svg" alt="Sellwild" />
    <img width="58" height="14" class="img-light" src="https://widget.sellwild.com/assets/logo.svg" alt="Sellwild" />
    </a>`
}

function poweredByBottomBar(theme: ICustomizations) {
  return bottombar(theme.linkText ? `
    <p>Powered by</p>
    <a target="_blank" href="https://sellwild.com" rel="nofollow sponsored noopener" class="sellwild-logo">
    <img width="58" height="14" class="img-dark" src="https://widget.sellwild.com/assets/logo_white.svg" alt="Sellwild" />
    <img width="58" height="14" class="img-light" src="https://widget.sellwild.com/assets/logo.svg" alt="Sellwild" />
    </a>` : '');
}

function listing({ height = 250 } = {}) {
  return `<a target="_blank" class="sellwild-listing" rel="nofollow sponsored noopener" data-loading="true" href="#">
    <div>
    <div class="listing-img-container" style="height: ${height}px"></div>
    <p class="listing-title"></p>
    <p class="listing-price-2">&nbsp;</p>
    <p class="listing-user"></p>
    </div>
</a>`;
}

function renderItem (ads: ReturnType<typeof generateAds>, type: string) {
    switch (type) {
        case 'G': return display(ads.useGoogleDisplay([300,250]))
        case 'b': return displayShort(ads.useGoogleDisplay([320,50]) || ads.useMobileBanner())
        case 'M': return display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())
        case 'D': return display(ads.useGoogleDisplay([300,250]) || ads.useBidstreamDisplay())
        case 'Z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1)
        case 'z': return ads.useZipRecruiter(Math.floor(Math.random() * 12) + 1, true)
        case '<': return '<div>'
        case '>': return '</div>'
        case 'i': return listing({ height: 136 })
        case 'l':
        case 'I':
        case 'L': return listing({ height: 176 })
        default: return ''
    }
}

function el(tagname: string, attrs: string, children: string) {
  return `<${tagname} ${attrs}>${children}</${tagname}>`;
}

function div(attrs: string, children: string) {
  return el("div", attrs, children);
}

const makeStyles = (theme: ICustomizations, maxWidth?: number) => `
${theme.fontUrl && theme.fontFamily
? `<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="${theme.fontUrl}" rel="stylesheet">`
: ""}
<style>
.sellwild-widget h4, .sellwild-widget p, .sellwild-widget a {
    font-family: ${theme.fontFamily ? theme.fontFamily : 'Arial, Helvetica, sans-serif;'}
    margin: 0;
    font-weight: normal;
    line-height: 1;
}

.sellwild-widget {
    ${maxWidth ? `max-width: ${maxWidth}px;` : ''}
    margin: auto;
    --text-color: #303030;
    --price-color: ${theme.linkColor || '#018ef0'};
    --user-color: #474747;
}

${theme.disableDark ? `` : `
@media screen and (prefers-color-scheme: dark) {
    .sellwild-widget {
        --text-color: #f1f1f1;
        --price-color: ${theme.linkColor || '#018ef0'};
        --user-color: #cecece;
    }
}
`}

:root[data-color-scheme=dark], .${theme.darkClass || 'dark-inner-bg'} .sellwild-widget {
    --text-color: #f1f1f1;
    --price-color: ${theme.linkColor || '#018ef0'};
    --user-color: #cecece;
}

.img-dark {
    display: none;
}

${theme.disableDark ? '' : `@media screen and (prefers-color-scheme: dark) {
    .img-dark { display: block; }
    .img-light { display: none; }
}

:root[data-color-scheme=dark] .img-dark, .${theme.darkClass || 'dark-inner-bg'} .img-dark {
    display: block;
}
:root[data-color-scheme=dark] .img-light, .${theme.darkClass || 'dark-inner-bg'} .img-dark {
    display: none;
}`}

.sellwild-topbar:not(:empty), .sellwild-bottombar:not(:empty) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.sellwild-topbar-titles {
    display: flex;
    align-items: baseline;
    grid-gap: 8px;
}

.sellwild-topbar h4, .sellwild-bottombar h4, .sellwild-topbar h6, .sellwild-bottombar h6 {
    margin: 0;
    margin-right: auto;
    color: var(--text-color);
    font-size: ${theme.titleSize}px;
}

.sellwild-widget .sellwild-topbar p, .sellwild-widget .sellwild-bottombar p {
    margin: 0;
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 0!important;
    line-height: 1!important;
    color: var(--text-color)!important;
    font-size: 10px!important;
}

.sellwild-topbar a, .sellwild-bottombar a {
    display: block;
    text-decoration: none;
    color: var(--price-color);
    display: flex;
    align-items: center;
    font-size: 14px;
}

.sellwild-topbar a:hover, .sellwild-bottombar a:hover {
    text-decoration: underline;
}

.sellwild-widget-grid {
    display: flex;
    grid-gap: 8px;
}

.sellwild-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.sellwild-listing:hover {
    text-decoration: none;
}

.sellwild-widget .sellwild-widget-grid a:first-child,
.sellwild-widget .sellwild-widget-grid div:first-child {
    align-items: flex-start;
    margin: 0!important;
}

.sellwild-widget .sellwild-widget-grid a:last-child,
.sellwild-widget .sellwild-widget-grid div:last-child {
    align-items: flex-end;
    margin: 0!important;
}

.sellwild-widget .sellwild-widget-banner {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
}

.sellwild-widget-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
}

.sellwild-widget-mobile .sellwild-widget-display {
    margin-bottom: 20px;
}

.sellwild-widget-display.short {
    height: 50px;
    margin-bottom: 10px;
}

.sellwild-listing {
    flex: 1;
}

.sellwild-listing>div {
    width: 100%;
}

.listing-img-container {
    display: block;
    transition: filter 0.3s ease-in;
    width: 100%;
}

.listing-img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listing-img-container:not(.get-featured):hover {
    filter: brightness(0.5);
}

.sellwild-widget .sellwild-listing .listing-title {
    color: var(--text-color);
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2em;
    line-height: 1;
    font-size: 14px;
    margin: 14px 0 10px 0;
}

.sellwild-widget .sellwild-listing .listing-price-2 {
    color: var(--price-color);
    font-size: 16px;
    line-height: 1;
    margin: 10px 0;
}

.sellwild-widget .sellwild-listing .listing-user {
    color: var(--user-color);
    font-size: 10px;
    font-weight: lighter;
    line-height: 1;
    margin-bottom: 20px;
}

.listing-user span {
    text-transform: uppercase;
}

a p:hover {
    text-decoration: underline;
}

${theme.css}
</style>`